.notLoggedIn {
    width: 100%;
    overflow-x: clip;
}

.section {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.section2 {
    background-color: #F6F7F9;
}

:global(.bp5-dark) .section2 {
    background-color: #252A31;
}

.callout {
    width: fit-content;
    font-size: large;
    margin: 10px;
}

.checklist {
    list-style: none;
    padding-left: 0px;
}

.columns {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.columnCell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    font-size: medium;
    width: 180px;
}

.columnCellIcon {
    width: 64px;
    height: 64px;
}

.imageWithCaptionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.imageCaptionContainer {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    font-size: medium;
}

.imageCaptionHeader {
    margin-top: 0px;
}

.communityIcon {
    width: 128px;
    height: 128px;
    fill: black;
}

:global(.bp5-dark) .communityIcon {
    fill: white;
}
