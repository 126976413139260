.card {
    align-items: center;
    color: white;
    display: flex;
    filter: drop-shadow(10px 10px 10px black);
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    left: 50vw;
    max-width: 80vw;
    overflow: hidden;
    position: absolute;
    top: calc(50px + (100vh - 50px) / 2);
    transform: translate(-50%, -50%);
    width: auto;
}

.header {
    font-size: x-large;
}

.playButton {
    font-weight: bold;
}
