.indicator {
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0;
    text-align: center;
    width: 100%;
}

.indicatorLoading {
    position: static;
    opacity: 1;
}

.icon {
    transition: transform 0.3s ease-in-out;
}

.iconRefresh {
    transform: rotate(180deg);
}
