.mapZoom {
    left: 0px;
    right: 0px;
    max-width: calc(100vw);
    max-height: calc(100vh - 50px);
    position: absolute;
    overflow: clip;
    z-index: 0;
}

.mapZoomOverlay {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
}

.mapZoomMapContainer {
    width: fit-content;
    height: fit-content;

    transition-duration: 15s, 5s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out;
}
